import React, { useEffect, useState } from 'react';
import { singleProfile } from '../../api/settings';
import { DESKIE_API as API } from '../../config';
import logo from "../../Assets/Images/logo/logo.svg";
import "./PublicResource.css"
import { useParams } from 'react-router-dom';
import { publicResourceBook, singleResource } from '../../api/resource';
import { format, parse, isWithinInterval } from "date-fns";

const PublicResource = () => {
    const screenWidth = window.innerWidth;
    const { id } = useParams();
    const [profile, setProfile] = useState<any>();
    const [bookList, setBookList] = useState<any[]>([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [selectedResource, setSelectedResource] = useState<any>({});

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getSingleProfile = async () => {
        try {
            const data = await singleProfile();
            setProfile(data.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getSingleProfile();
        if (id) {
            publicResourceBook(id).then((data) => {
                const now = new Date();
                const today = format(now, "yyyy-MM-dd"); // Get today's date in YYYY-MM-DD format
                const filteredData = data.map((item: { book_date: string, start_time: string, end_time: string }) => {
                    const startTime = parse(item.start_time, "h a", new Date());
                    const endTime = parse(item.end_time, "h a", new Date());
                    startTime.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
                    endTime.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
                    const booked_time = isWithinInterval(now, { start: startTime, end: endTime });
                    return { ...item, booked_time, };
                }).filter((item: any) => item.book_date.startsWith(today));
                setBookList(filteredData);
            }).catch((err) => {
                console.log(err);
            });
            singleResource(id).then((data) => {
                setSelectedResource(data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [id]);

    return (
        <section className='visitorSection' style={{
            background: screenWidth <= 800
                ? `linear-gradient(rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.94)), url(${selectedResource && `${API}/${encodeURI(selectedResource.image)}`})`
                : 'none', // or a different background for wider screens
        }}>
            <div className="visitorLeft">
                <div className='visitorImage' style={{ background: `linear-gradient(rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.94)), url(${selectedResource && `${API}/${encodeURI(selectedResource.image)}`})` }}>
                    <div>
                        {profile && profile.company_logo_dark ?
                            <img src={`${API}/${profile.company_logo_dark}`} alt="logo" style={{ maxWidth: '280px', maxHeight: '75px' }} />
                            : <img src={logo} alt="logo" />
                        }
                        <h5 className='mt-3'>{selectedResource && selectedResource.name}</h5>
                    </div>

                </div>
            </div>

            <div className="visitorRight resourceBooked">
                <div className="bookedTime">
                    <h5 className='mb-4'> {currentTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}</h5>
                    <p>{currentTime.toLocaleDateString(undefined, { weekday: "long", month: "long", day: "numeric", year: "numeric" })}</p>
                </div>
                <div className="bookedList">
                    {bookList && bookList.length ? <>{bookList &&
                        bookList.map((resource, i: number) => (<div className={resource.booked_time ? "bookedTime bookedResources" : "bookedResources"}>
                            <div className="creatorName">
                                <p>{resource.creator_name}</p>
                            </div>
                            <div className="selectBookedTime">
                                <span>{resource.start_time} - {resource.end_time}</span>
                            </div>
                        </div>))}</> : <p className="text-center text-danger fw-bold">No Result Found</p>}

                </div>
            </div>
        </section>
    )
}

export default PublicResource