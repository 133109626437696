import React, { useState, useEffect } from 'react';
import Layout from './../../Component/Layout/Layout';
import { Dropdown, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import trashIcon from "../../Assets/Images/icon/trash-icon.svg";
import downloadIcon from "../../Assets/Images/icon/download-02.svg";
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { CSVLink } from 'react-csv';
import arrowDown from "../../Assets/Images/icon/downIcon.svg";
import { getTourList, tourDelete } from '../../api/tour';
import Pagination from '../../Component/Pagination/Pagination';
import DeleteModal from '../../Component/DeleteModal/DeleteModal';
import { parseISO, format } from 'date-fns';
import linkIcon from "../../Assets/Images/icon/Featured_icon.svg";
import copyIcon from "../../Assets/Images/icon/copy-01.svg";
import PhoneInput from 'react-phone-input-2';

interface Tour {
  id: string;
  tour_day: string;
  tour_date: string;
  tour_time: string;
  name: string;
  phone: string;
  email: string;
}

const Tours = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tourList, setTourList] = useState<Tour[]>([]);
  const [filteredTour, setFilteredTour] = useState<Tour[]>([]);
  const [count, setCount] = useState<number>(0);
  const [status, setStatus] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' | null }>({ key: '', direction: 'desc' });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [limitValue, setLimitValue] = useState<number>(0);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedTour, setSelectedTour] = useState<Tour>();

  useEffect(() => {
    if (pageCount > 1) {
      setPrevButton(true)
    }
    if (page === 1) {
      setPrevButton(false)
    }
    // next button
    if (pageCount > 1) {
      setNextButton(true)
    }
    if (pageCount === page) {
      setNextButton(false)
    }
  }, [pageCount, page])


  const nextPage = () => {
    setPage(page + 1)
    setNextButton(true)
  }

  const prevPage = () => {
    setPage(page - 1)
  }

  function formatDate(dateStr: string) {
    if (!dateStr) {
      return 'Invalid date'
    }
    const date = parseISO(dateStr)
    return format(date, 'MMMM d, yyyy')
  }

  function formatDateTime(dateStr: string, timeStr: string) {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    const timeParts: any = timeStr.match(/(\d+):?(\d*)\s*(AM|PM)?/i);
    if (!timeParts) {
      return "Invalid time";
    }
    let [_, hours, minutes, period] = timeParts;
    hours = parseInt(hours, 10);
    minutes = minutes ? parseInt(minutes, 10) : 0;
    if (period) {
      if (period.toUpperCase() === 'PM' && hours < 12) {
        hours += 12;
      } else if (period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0;
      }
    }
    date.setHours(hours, minutes);
    return date.toISOString();
  }

  function processDateTime(tour_date: string, tour_time: string) {
    const formattedDate = formatDate(tour_date);
    const tourDateTime = formatDateTime(tour_date, tour_time);
    return { date: formattedDate, time: tour_time, dateTime: tourDateTime };
  }

  useEffect(() => {
    getTourList(limit, page, searchTerm).then((data) => {
      const processedTourList = data.tour.map((tour: any) => {
        const { date, time, dateTime } = processDateTime(tour.tour_date, tour.tour_time);
        return {
          ...tour,
          tour_date: date,
          tour_time: time,
          tour_date_time: dateTime
        };
      });
      setTourList(processedTourList);
      handleSort('tour_date_time', processedTourList);
      setTotalValue(data.total)
      setLimitValue(data.limit)
      setPageValue(data.page);
    }).catch((err) => {
      console.log(err);
    })
  }, [page, count, limit, searchTerm]);





  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key: string, list?: Tour[]) => {

    let direction: 'asc' | 'desc' = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc' && !list) {
      direction = 'asc';
    }
    if (!list) {
      list = [...filteredTour];
    }
    setSortConfig({ key, direction });
    const sortedTours = list.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setFilteredTour(sortedTours);
  };

  const handleStatusFilter = (status: string) => {
    setStatus(status);
    if (status === 'all') {
      setFilteredTour(tourList);
    } else if (status === 'upcoming') {
      const now = new Date();
      const upcomingTours = tourList.filter(tour => new Date(tour.tour_date) >= now);
      setFilteredTour(upcomingTours);
    }
  };

  const showDeletePopup = (tour: Tour) => {
    setSelectedTour(tour);
    setDeleteShow(true);
  }

  const deleteTour = () => {
    if (!selectedTour) {
      return
    }
    tourDelete(selectedTour.id).then((data) => {
      showNotifications(TOAST_TYPE.success, "Tour deleted successfully");
      setCount(count + 1);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setDeleteShow(false);
    });
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email Address", key: "email" },
    { label: "Check In Date", key: "tour_date" },
    { label: "Check In Time", key: "tour_time" },
  ];

  const csvData = filteredTour.map((tour: any) => ({
    name: tour.name,
    email: tour.email,
    tour_date: tour.tour_date,
    tour_time: tour.tour_time,
  }));

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${window.location.origin}/public/tours`)
  };

  return (
    <div id='tour'>
      <Layout>
        <div className='mainContent' id='tour-log'>
          <div className="visitorLink">
            <img src={linkIcon} alt="feature" />
            <div className='visitorLinkUrl'>
              <h5>Your public Tour Booking URL is:</h5>
              <h6 onClick={handleCopyClick}><img src={copyIcon} alt="copy" /> {window.location.origin}/public/tours</h6>
            </div>
            <FontAwesomeIcon icon={faXmark} />
          </div>
          <div className="memberBox visitorTableList">
            <div className="topLine">
              <div className='tableHeading'>
                <h6>All Tours</h6>
              </div>
              <div className='memberSearch'>
                <div className='searchInput' style={{ marginRight: '15px' }}>
                  <input type="text" placeholder='Search tours' onChange={handleInputChange} className='form-control' />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className='filterDropdown'>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <button className='filterBtn'>{status ? status : 'Status'}  <img src={arrowDown} alt='filter' /></button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleStatusFilter("all")}>All</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleStatusFilter("upcoming")}>Upcoming</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <CSVLink className="visitorDownload" data={csvData} headers={headers} filename={"tours.csv"}>
                  <button><img src={downloadIcon} alt="downloadIcon" />&nbsp; Download CSV</button>
                </CSVLink>
              </div>
            </div>

            <div className="memberList visitorList">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <label className="tableCheckBox">
                        <div className="contactCheck">
                          <input type="checkbox" name="agreement" />
                          <span className="checkmark"></span>
                        </div>
                      </label>
                    </th>
                    <th onClick={() => handleSort('name')}>
                      Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                    </th>
                    <th onClick={() => handleSort('email')}>
                      Email Address {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                    </th>
                    <th>
                      Phone Number
                    </th>
                    <th onClick={() => handleSort('tour_date_time')}>
                      Time / Date {sortConfig.key === 'tour_date_time' && (sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTour && filteredTour.map((tour: any, index: number) => <tr key={`refer` + index}>
                    <td>
                      <label className="tableCheckBox">
                        <div className="contactCheck d-flex">
                          <input type="checkbox" name="agreement" />
                          <span className="checkmark"></span>
                        </div>
                      </label>
                    </td>
                    <td className='tableAction'>{tour.name}</td>
                    <td className='tableAction'>{tour.email}</td>
                    <td className='tableAction memberPhone'><PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={tour.phone} /></td>
                    <td className='tableAction'>
                      <span style={{ marginBottom: '1rem' }}>{tour.tour_date}</span><br />{tour.tour_time}
                    </td>
                    <td className='tableAction'>
                      <button className='btn removeVisitor' onClick={() => showDeletePopup(tour)}>
                        <img src={trashIcon} alt="trash" />
                      </button>
                    </td>
                  </tr>)}
                </tbody>
              </Table>
              <Pagination page={page} paginationTitle="items" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={filteredTour} />
            </div>
          </div>
        </div>
        <DeleteModal title='Are you sure you want to delete this tour?' deleteShow={deleteShow} deleteApi={deleteTour} handleDeleteClose={() => setDeleteShow(false)} />

      </Layout>
    </div>
  )
}

export default Tours;
