import React, { useEffect, useState } from 'react';
import "./LandingPage.css";
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import wifi from "../../Assets/Images/icon/large_wifi.svg";
import meeting from "../../Assets/Images/icon/meeting_room.svg";
import group from "../../Assets/Images/icon/groups_3.svg";
import lock from "../../Assets/Images/icon/lock_open.svg";
import print from "../../Assets/Images/icon/print.svg";
import mail from "../../Assets/Images/icon/mail_outline.svg";
import { contactSupport, getGlobalEditor } from '../../api/editor';
import { DESKIE_API as API } from '../../config';
import { publicResourceList } from '../../api/resource';
import { getSpacesList } from '../../api/spaces';
import { singleProfile } from '../../api/settings';


import { GoogleMap, useLoadScript, Marker, Autocomplete } from "@react-google-maps/api";
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { useFetchCurrency } from '../../CommonFunction/Function';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    responsive: [
        {
            breakpoint: 1124, // For screens below 1024px
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 600, // For screens below 600px
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const LandingPage = () => {
    const [editor, setEditor] = useState<any>({});
    const [resourceLists, setResourceLists] = useState([]);
    const [spacesList, setSpacesList] = useState([]);
    const [profileList, setProfileList] = useState<any>({});
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactCompany, setContactCompany] = useState("");
    const [contactMessage, setContactMessage] = useState("");
    const { currencyIcon, fetchCurrency } = useFetchCurrency();
    const [themeColor, setThemeColor] = useState("");
    const [headingText, setHeadingText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [headingFamily, setHeadingFamily] = useState("");
    const [bodyFamily, setBodyFamily] = useState("");

    useEffect(() => {
        fetchCurrency();
        getGlobalEditor().then((data) => {
            setEditor(data.data);
            setThemeColor(data.data.theme_color);
            setHeadingText(data.data.header_font);
            setBodyText(data.data.body_font);
            setHeadingFamily(data.data.header_family);
            setBodyFamily(data.data.body_family);
        }).catch((err) => {
            console.log(err);
        });

        // resource
        publicResourceList(2, 1, "", "public").then((data) => {
            setResourceLists(data.data);
        })
            .catch((err) => {
                console.log(err);
            });

        getSpacesList(3, 1, "").then((data) => {
            setSpacesList(data && data.spaces);
        })
            .catch((err) => {
                console.log(err);
            });

        singleProfile().then((data) => {
            setProfileList(data.data);
        })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    useEffect(() => {
        if (themeColor) {
            document.documentElement.style.setProperty("--theme-color", themeColor);
        }
        if (headingText) {
            document.documentElement.style.setProperty("--theme-heading", headingText);
        }
        if (bodyText) {
            document.documentElement.style.setProperty("--theme-paragraph", bodyText);
        }
        const style = document.createElement('style');
        style.textContent = `
          @font-face {
            font-family: "${headingText}";
            src: url("${headingFamily}") format("truetype");
          }
        `;
        style.textContent = `
          @font-face {
            font-family: "${bodyText}";
            src: url("${bodyFamily}") format("truetype");
          }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };

    }, [themeColor, headingText, bodyFamily, headingFamily]);



    const messageSupport = () => {
        let editorJson = {
            "contactName": contactName,
            "contactEmail": contactEmail,
            "contactPhone": contactPhone,
            "contactCompany": contactCompany,
            "contactMessage": contactMessage,
            "messageEmail": editor.contact_email
        }
        contactSupport(editorJson).then((data) => {
            showNotifications(TOAST_TYPE.success, data.message);
            // setCount(count + 1)
        }).catch((err) => { console.log(err); });
    }

    const removeWidthAndHeight = (html: string) => {
        // Regex to remove width and height attributes
        return html.replace(/(width|height)=[^"]*"/g, "");
    };

    // Clean the iframe string by removing width and height
    const cleanedMap = editor.google_map ? removeWidthAndHeight(editor.google_map) : "";

    return (
        <section className='landingPage'>
            {/* Top navbar */}
            <div className="topHeader" id='home'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="contactInfo">
                                <Link to=""><FontAwesomeIcon icon={faPhone} /> {editor?.phone}</Link>
                                <Link to=""><FontAwesomeIcon icon={faEnvelope as any} /> {editor?.email}</Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="contactInfoIcon d-flex justify-content-end">
                                <Link to={`${profileList.company_facebook}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF as any} /></Link>
                                <Link to={`${profileList.company_instagram}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram as any} /></Link>
                                <Link to={`${profileList.company_linkedin}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn as any} /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Navbar */}
            <div className="landingNavbar">
                <Navbar expand="lg" className="w-100">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={`${API}/${editor.header_logo}`} className="widthLogo" alt="change-logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" />

                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="mx-auto navbarMiddle">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#about">About</Nav.Link>
                                <Nav.Link href="#features">Features</Nav.Link>
                                <Nav.Link href="#testimonials">Testimonials</Nav.Link>
                                <Nav.Link href="#contact">Contact</Nav.Link>
                            </Nav>
                            {editor.login_button ? <Link to="/" className='loginBtn'>Login</Link> : ""}
                            {editor.header_book_a_tour ? <Link to="/public/tours" className='bookATour'>Book a Tour</Link> : ""}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            {/* <div>
                <div style={{ marginBottom: "10px" }}>
                    <Autocomplete
                        onLoad={(autocompleteInstance) => setAutocomplete(autocompleteInstance)}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type="text"
                            placeholder="Search for a location"
                            style={{ width: "300px", padding: "10px" }}
                        />
                    </Autocomplete>
                </div>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={10}
                    center={markerPosition || center}
                >
                    {markerPosition && (
                        <Marker position={markerPosition} />
                    )}
                </GoogleMap>
                {markerPosition && (
                    <div>
                        <p>Latitude: {markerPosition.lat}</p>
                        <p>Longitude: {markerPosition.lng}</p>
                    </div>
                )}
            </div> */}
            {/* Banner */}
            <section className="landingBanner">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="landingImage" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${editor.hero_logo && `${API}/${encodeURI(editor.hero_logo)}`})` }}>
                                <h1>{editor.hero_header}</h1>
                                <p>{editor.hero_sub_header}</p>
                                <div className='mt-2'>
                                    {editor.rentable_button ? <Link to="" className='checkAvailable'>Check Availability</Link> : ""}
                                    {editor.bookable_button ? <Link to="" className='bookSpaces' style={{ background: "white" }}>Bookable Spaces</Link> : ""}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Meet Hive */}
            <section className="community" id='about'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="meetBox">
                                <div className="landingHeading">
                                    <h2>{editor.about_header}</h2>
                                </div>
                                <p>{editor.about_content}</p>
                                {editor.about_book_a_tour ? <Link to="/public/tours" className='checkAvailable'>Book a Tour</Link> : ""}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="meetImage">
                                <img src={`${API}/${editor.about_logo}`} alt="card01" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Available spaces */}
            <section className="community">
                <Container>
                    <Row>
                        <Col md={12} className='d-flex justify-content-between align-items-center mb-5'>
                            <div className="landingHeading">
                                <h2>Available Spaces</h2>
                            </div>
                            {editor.assets_book_a_tour ? <Link to="" className='bookSpaces'>Bookable Spaces</Link> : ""}
                        </Col>
                        {spacesList.map((data: any) =>
                            <Col md={4}>
                                <div className="availableCard">
                                    <img src={`${API}/${data.space_image}`} alt="wifi" />
                                    <div className="spacesRate">
                                        <h6>{data.name}</h6>
                                        <p>{currencyIcon} {data.rate}</p>
                                    </div>
                                </div>
                            </Col>)}
                    </Row>
                </Container>
            </section>
            {/* Why hive */}
            {editor.amenities_section ? <section className="community" id="features">
                <Container>
                    <Row>
                        <Col md={12} className='mb-5'>
                            <div className="landingHeading">
                                <h2>Why Hive Coworking?</h2>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={wifi} alt="wifi" />
                                <p>High-Speed Internet</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={meeting} alt="wifi" />
                                <p>Meeting Rooms</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={group} alt="wifi" />
                                <p>Community Events</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={lock} alt="wifi" />
                                <p>24/7 Access</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={print} alt="wifi" />
                                <p>Printing Services</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="hiveCard">
                                <img src={mail} alt="wifi" />
                                <p>Mail Handling</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> : ""}
            {/* Random Image */}
            {editor.gallery_section ? <section className="community">
                <Container>
                    <Row>
                        <Col md={8} className="d-flex align-items-stretch">
                            <div className="randomImage w-100">
                                <img src={`${API}/${editor.gallery_one}`} alt="wifi" className="img-fluid h-100" />
                            </div>
                        </Col>
                        <Col md={4} className="d-flex flex-column">
                            <div className="randomImage flex-grow-1">
                                <img src={`${API}/${editor.gallery_two}`} alt="wifi" className="img-fluid h-100" />
                            </div>
                            <div className="randomImage mt-3 flex-grow-1">
                                <img src={`${API}/${editor.gallery_three}`} alt="wifi" className="img-fluid h-100" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> : ""}
            {/* Booking resources */}
            {editor.resource_section ? <section className="community">
                <Container>
                    <Row>
                        <Col md={12} className='d-flex justify-content-between align-items-center mb-5'>
                            <div className="landingHeading">
                                <h2>Bookable Resources</h2>
                            </div>
                            <Link to="" className='checkAvailable'>Check Availability</Link>
                        </Col>
                        {resourceLists.map((data: any) => <Col md={6}>
                            <div className="availableCard">
                                <img src={`${API}/${data.image}`} alt="wifi" />
                                <div className="spacesRate">
                                    <h6>{data.name} <span>({data.type})</span></h6>
                                    <p>{currencyIcon}{data.public_rate}/{data.public_time}</p>
                                </div>
                            </div>
                        </Col>)}
                    </Row>
                </Container>
            </section> : ""}
            {/* Community */}
            {editor.contact_section ? <section className="community">
                <Container>
                    <Row>
                        <Col md={12} className='mb-5'>
                            <div className="landingHeading">
                                <h2>Hear From Our Community</h2>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="communityCard">
                                <div className='communityHeading'>
                                    <img src={`${API}/${editor.testimonial_one}`} alt="logo" />
                                    <div>
                                        <h6>{editor.tes_header_one}</h6>
                                        <p>Marketer</p>
                                    </div>
                                </div>
                                <div className="communityContent">
                                    <p>{editor.tes_content_one}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="communityCard">
                                <div className='communityHeading'>
                                    <img src={`${API}/${editor.testimonial_two}`} alt="logo" />
                                    <div>
                                        <h6>{editor.tes_header_two}</h6>
                                        <p>Marketer</p>
                                    </div>
                                </div>
                                <div className="communityContent">
                                    <p>{editor.tes_content_two}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="communityCard">
                                <div className='communityHeading'>
                                    <img src={`${API}/${editor.testimonial_three}`} alt="logo" />
                                    <div>
                                        <h6>{editor.tes_header_three}</h6>
                                        <p>Marketer</p>
                                    </div>
                                </div>
                                <div className="communityContent">
                                    <p>{editor.tes_content_three}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> : ""}
            {/* Faq */}
            {editor.testimonial_section ? <section className="community">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="faqBox">
                                <div className="landingHeading">
                                    <h2>FAQ</h2>
                                </div>
                                <div className="faqContent">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    {editor.faq_que_one}
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {editor.faq_ans_one}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                    {editor.faq_que_two}
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {editor.faq_ans_two}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                    {editor.faq_que_three}
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {editor.faq_ans_three}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                    {editor.faq_que_four}
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {editor.faq_ans_four}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                                    {editor.faq_que_five}
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {editor.faq_ans_five}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="faqImage">
                                <img src={`${API}/${editor.faq_logo}`} alt="card01" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> : ""}
            {/* Instagram Carousel */}
            {/* <section className="community" id='testimonials'>
                <Container>
                    <Row>
                        <div className="testimonialHeading">
                            <div className="instagramBtn">

                            </div>
                        </div>
                        <Col md={12} className='imageSlider'>
                            <Slider {...settings}>
                                <div className='slideImg'>
                                    <img src={slider01} alt="slider01" width="100%" />
                                </div>
                                <div className='slideImg'>
                                    <img src={slider02} alt="slider01" width="100%" />
                                </div>
                                <div className='slideImg'>
                                    <img src={slider03} alt="slider01" width="100%" />
                                </div>
                                <div className='slideImg'>
                                    <img src={slider04} alt="slider01" width="100%" />
                                </div>
                                <div className='slideImg'>
                                    <img src={slider01} alt="slider01" width="100%" />
                                </div>
                                <div className='slideImg'>
                                    <img src={slider02} alt="slider01" width="100%" />
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            {/* Contact */}
            {editor.contact_section ? <section className="community" id="contact">
                <Container className='contactBox'>
                    <Row>
                        <Col md={7}>
                            <div className="contactForm mb-2">
                                <div className="landingHeading">
                                    <h2>Get in Touch</h2>
                                </div>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="contactInput">
                                        <label>Name</label>
                                        <input type="text" value={contactName} onChange={(e) => setContactName(e.target.value)} className='form-control' placeholder='Enter your name' />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="contactInput">
                                        <label>Email</label>
                                        <input type="text" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} className='form-control' placeholder='Enter your e-mail' />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="contactInput">
                                        <label>Phone</label>
                                        <input type="text" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} className='form-control' placeholder='Enter your phone' />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="contactInput">
                                        <label>Company</label>
                                        <input type="text" value={contactCompany} onChange={(e) => setContactCompany(e.target.value)} className='form-control' placeholder='Enter your company' />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="contactInput">
                                        <label>Message</label>
                                        <textarea className='form-control' value={contactMessage} onChange={(e) => setContactMessage(e.target.value)} placeholder='Enter your message'></textarea>
                                        <button className='checkAvailable' onClick={messageSupport}>Send Message</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <div className="contactMap">
                                <div dangerouslySetInnerHTML={{ __html: editor.google_map }} />
                                {/* {editor.google_map} */}
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d310055.32192470005!2d-74.30934183723556!3d40.69753994783581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e1!3m2!1sen!2sbd!4v1734458181601!5m2!1sen!2sbd" loading="lazy"></iframe> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> : ""}
            {/* Footer */}
            <section className="landingFooter">
                <Container className='footerBox'>
                    <Row>
                        <Col md={6}>
                            <div className="footerDescription">
                                <img src={`${API}/${editor.footer_logo}`} className="widthLogo" alt="change-logo" />
                                <p className='mt-2'>{editor.footer_content}</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="footerFollowUs">
                                <h6>Follow Us</h6>
                                <div className="footerInfoIcon d-flex justify-content-end">
                                    <Link to={`${profileList.company_facebook}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF as any} /></Link>
                                    <Link to={`${profileList.company_instagram}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram as any} /></Link>
                                    <Link to={`${profileList.company_linkedin}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn as any} /></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='copyRights'>
                    <Row>
                        <Col md={12}>
                            <p>Copyright © 2024 <Link to={`${profileList.website}`} target="_blank">{profileList.company_name}</Link></p>
                        </Col>
                    </Row>
                </Container>
                <Container className='poweredByFooter'>
                    <Row>
                        <Col md={12}>
                            <p>Powered by <Link to="https://www.deskie.com/">Deskie.com</Link></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </section>
    )
}

export default LandingPage