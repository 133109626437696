import React, { useState, useEffect } from 'react';
import Layout from '../../../Component/Layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faPeopleGroup, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Table } from 'react-bootstrap';
import { DESKIE_API as API } from '../../../config';
import filter from '../../../Assets/Images/icon/filter-lines.svg';
import download from "../../../Assets/Images/icon/download-cloud-02.svg";
import deleteIcon from "../../../Assets/Images/icon/trash-02.svg";
import UploadFile from '../../../Component/UploadFile/UploadFile';
import { favoriteFile, filesDelete, getFilesList } from '../../../api/files';
import { convertBytesToSize } from '../../../CommonFunction/Function';
import moment from 'moment';
import imgExtension from "../../../Assets/Images/icon/feature-image.png";
import fileExtension from "../../../Assets/Images/icon/feature-file.png";
import videoExtension from "../../../Assets/Images/icon/feature-video.png";
import unknownExtension from "../../../Assets/Images/icon/feature-unknown.png";
import { showNotifications, TOAST_TYPE } from '../../../CommonFunction/toaster';
import { getMemberList } from '../../../api/member';
import ShareFile from '../../../Component/UploadFile/ShareFile';
import DeleteModal from '../../../Component/DeleteModal/DeleteModal';
import memberIcon from "../../../Assets/Images/icon/memberAvatar.svg";
import Pagination from '../../../Component/Pagination/Pagination';
import LightBox from '../../../Component/LightBox/LightBox';
import dots from "../../../Assets/Images/icon/dots-vertical_black.svg";
import "./MyFiles.css";
import MobileFiles from './MobileFiles';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import uploadSvg from "../../../Assets/Images/icon/upgrade.svg";

const MyFiles = () => {

    const [filesList, setFilesList] = useState([]);
    const [count, setCount] = useState(0);
    const [filesId, setFilesId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [deleteShow, setDeleteShow] = useState(false);
    const handleDeleteClose = () => setDeleteShow(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [lightBoxVisible, setLightBoxVisible] = useState(false);
    const [filterTag, setFilterTag] = useState('');
    // pagination number
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(10);
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [uploadShow, setUploadShow] = useState(false);
    const handleUploadClose = () => setUploadShow(false);
    const [lightBoxShow, setLightBoxShow] = useState(false);
    const [lightBoxFile, setLightBoxFile] = useState("");
    const [shareShow, setShareShow] = useState(false);
    const handleShareClose = () => setShareShow(false);
    const [sharesShow, setSharesShow] = useState<any>([]);
    const [shares, setShares] = useState<any>([]);
    const fileUpload = () => {
        setUploadShow(true);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getFilesList(limit, page, filterTag, searchTerm).then((data) => {
            setFilesList(data && data.files);
            setTotalValue(data && data.total);
            setLimitValue(data && data.limit);
            setPageValue(data && data.page);
        }).catch((err) => { console.log(err) });

    }, [uploadShow, count, shareShow, page, filterTag, searchTerm]);




    const getFileType = (extension: string) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const videoExtensions = ['mp4', 'avi', 'mov'];
        const documentExtensions = ['pdf', 'doc', 'docx', 'txt'];

        if (imageExtensions.includes(extension)) {
            return imgExtension;
        } else if (videoExtensions.includes(extension)) {
            return videoExtension;
        } else if (documentExtensions.includes(extension)) {
            return fileExtension;
        } else {
            return unknownExtension;
        }
    };


    // delete files
    const fileRemove = (id: string) => {
        setDeleteShow(true);
        setDeleteId(id);
    }
    const deleteApi = () => {
        filesDelete(deleteId).then(() => {
            showNotifications(TOAST_TYPE.success, 'Files deleted successfully');
            setCount(count + 1);
        }).catch((err: any) => {
            console.log(err);
        }).finally(() => {
            setDeleteShow(false);
        })
    }
    // download file
    const handleDownloadClick = async (fileName: string) => {
        const imageUrl = `${API}/${fileName}`;
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };



    const shareModal = (fileId: string, shareList: any) => {
        setFilesId(fileId);
        setShareShow(true);
        setSharesShow(shareList);
    }
    useEffect(() => {
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [pageCount, page])


    const nextPage = () => {
        setPage(page + 1)
        setNextButton(true)
    }

    const prevPage = () => {
        setPage(page - 1)
    }

    const filteredFiles = filesList?.filter((member: any) =>
        member.nick_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.extension.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const lightBox = (fileName: string) => {
        setLightBoxShow(true);
        setLightBoxFile(fileName);
        setLightBoxVisible(true);
    }
    const closeLightBox = () => {
        setLightBoxVisible(false);
    };
    return (
        <>
            <Layout>
                <div className='mainContent'>
                    <div className="myFilesTable">
                        <div className="topLine mt-1">
                            <div className='tableHeading'>
                                <h6>All Files</h6>
                            </div>
                            <div className="mobileFilesHeader mb-3">
                                <div className='searchInput' style={{ marginRight: '15px' }}>
                                    <input type="text" placeholder='Search files' onChange={(e) => setSearchTerm(e.target.value)} className='form-control' />
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <div className='filterDropdown taskDropdown'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <button className='filterBtn'>{filterTag === "created" ? <FontAwesomeIcon icon={faUser as any} /> : filterTag === "member" ? <FontAwesomeIcon icon={faPeopleGroup as any} /> : filterTag === "all" ? "All" : <img className='mr-2' src={filter} alt='filter' />}</button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setFilterTag('all')}>All Files</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilterTag('created')}>My Files</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilterTag('member')}>Shared With Me</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <button onClick={() => fileUpload()} className='uploadIcon'><img src={uploadSvg} alt="upload icon" /></button>
                            </div>
                            <div className='memberSearch'>
                                <div className='searchInput'>
                                    <input type="text" placeholder='Search files' onChange={(e) => setSearchTerm(e.target.value)} className='form-control' />
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <div className='filterDropdown taskDropdown'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <button className='filterBtn'><img className='mr-2' src={filter} alt='filter' />{filterTag === "created" ? "My Files" : filterTag === "member" ? "Shared With Me" : filterTag === "all" ? "All Files" : "Filters"}</button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setFilterTag('all')}>All Files</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilterTag('created')}>My Files</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilterTag('member')}>Shared With Me</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <button onClick={() => fileUpload()}><FontAwesomeIcon icon={faPlus} /> Upload File</button>
                            </div>
                            <div className="mobileFilesList">
                                {filteredFiles && filteredFiles.map((file: any, index) =>
                                    <div className="mobileFile">
                                        <div className='fileBox'>
                                            <div className="filesIcon">
                                                <img src={getFileType(file.extension)} alt="avatar" />
                                            </div>
                                            <div className="filesDetails">
                                                <h5 onClick={() => lightBox(file.files_upload)}>{file.nick_name.length > 20 ? `${file.nick_name.substring(0, 20)}...` : file.nick_name}</h5>
                                                <p><span className='date'>{moment(file.created_at).format('MMMM D, YYYY')}</span> <span className='fileSize'>{convertBytesToSize(file.size)}</span></p>
                                            </div>
                                            <div className="filesDropdown">
                                                <div className="trashPost">
                                                    <Dropdown className='p-0'>
                                                        <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-toggle p-0'>
                                                            <img className="line-chart-up-04-icon" alt="" src={dots} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className='postDelete'>
                                                            <Dropdown.Item onClick={() => handleDownloadClick(file.name)} className='custom-dropdown-toggle'><img src={download} alt="download" /> Download</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => fileRemove(file.id)} className='custom-dropdown-toggle'><img src={deleteIcon} alt="delete" /> Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filesShareBtn">
                                            <div className="avatars2">
                                                {file.sharesList.map((share: any, i: number) =>
                                                    <div key={`myShare` + i}>{share.image === "imageNull" ? <img className="avatar-icon36 default" alt="" src={memberIcon} />
                                                        : <img className="avatar-icon36" alt="" src={`${API}/${share.image}`} />
                                                    }</div>
                                                )}
                                                {file.delete ?
                                                    <div className="avatar2" onClick={() => shareModal(file.id, file.shares)}>
                                                        +
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                        <div className="mobilePagination">
                            <Pagination page={page} paginationTitle="files" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={filesList} />
                        </div>
                        <div className="filesList myFilesList pcFilesList">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th><label className="tableCheckBox">
                                            <div className="contactCheck">
                                                <input type="checkbox" name="agreement" />
                                                <span className="checkmark"></span></div>
                                        </label></th>
                                        <th>Name <FontAwesomeIcon icon={faArrowUp} /></th>
                                        <th>Uploaded <FontAwesomeIcon icon={faArrowUp} /></th>
                                        <th>Size <FontAwesomeIcon icon={faArrowUp} /></th>
                                        <th>Sharing</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredFiles && filteredFiles.map((file: any, index) => <tr key={`myFiles` + index}>
                                        <td><label className="tableCheckBox">
                                            <div className="contactCheck">
                                                <input type="checkbox" name="agreement" />
                                                <span className="checkmark"></span></div>
                                        </label></td>
                                        <td onClick={() => lightBox(file.files_upload)} style={{ cursor: "pointer" }}><img src={getFileType(file.extension)} alt="avatar" /> {file.nick_name}.{file.extension}</td>
                                        <td>{moment(file.created_at).format('MMMM D, YYYY')}</td>
                                        <td>{convertBytesToSize(file.size)}</td>
                                        {file.sharesList.length ? <td>
                                            <div className="avatars2">
                                                {file.sharesList.map((share: any, i: number) =>
                                                    <div key={`myShare` + i}>{share.image === "imageNull" ? <img className="avatar-icon36 default" alt="" src={memberIcon} />
                                                        : <img className="avatar-icon36" alt="" src={`${API}/${share.image}`} />
                                                    }</div>
                                                )}
                                                {file.delete ?
                                                    <div className="avatar2" onClick={() => shareModal(file.id, file.shares)}>
                                                        +
                                                    </div>
                                                    : ""}
                                            </div>
                                        </td>
                                            : <td className='tableAction'><button className='btn assignBtn' onClick={() => shareModal(file.id, file.shares)}>Share</button></td>
                                        }
                                        <td className='tableAction'>
                                            <button className='btn download' onClick={() => handleDownloadClick(file.name)}><img src={download} alt="download" /></button>
                                            {file.delete ?
                                                <button className='btn delete' onClick={() => fileRemove(file.id)}><img src={deleteIcon} alt="delete" /></button>
                                                : ""}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                            <Pagination page={page} paginationTitle="files" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={filesList} />
                        </div>
                    </div>
                </div>
                <UploadFile uploadShow={uploadShow} setUploadShow={setUploadShow} handleUploadClose={handleUploadClose} />
                <ShareFile shares={shares} setShares={setShares} sharesShow={sharesShow} setSharesShow={setSharesShow} filesId={filesId} shareShow={shareShow} setShareShow={setShareShow} handleShareClose={handleShareClose} />
                <DeleteModal deleteShow={deleteShow} deleteApi={deleteApi} handleDeleteClose={handleDeleteClose} />
                <MobileFiles show={show} setShow={setShow} handleClose={handleClose} filteredFiles={filteredFiles} shareModal={shareModal} getFileType={getFileType} fileUpload={fileUpload} setSearchTerm={setSearchTerm} page={page} setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} filesList={filesList} />
                {lightBoxVisible && <LightBox lightBoxFile={lightBoxFile} handleLightBoxClose={closeLightBox} />}
            </Layout>
        </>
    )
}

export default MyFiles