import React, { useState, useEffect } from "react";
import { Dropdown, Table } from "react-bootstrap";
import "./Billing.css";
import Layout from "../../Component/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import more from "../../Assets/Images/icon/dots-vertical.svg";
import arrowDown from "../../Assets/Images/icon/downIcon.svg";
import { DESKIE_API as API } from "../../config";
import moment from "moment";
import { Link } from "react-router-dom";
import { getInvoicesList, paymentVoid } from "../../api/invoice";
import { useNavigate } from "react-router-dom";
import memberAvatar from "../../Assets/Images/icon/memberAvatar.svg";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import Pagination from "../../Component/Pagination/Pagination";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import updateQueryString from "../../utils/updateQueryString";
import { useFetchCurrency } from "../../CommonFunction/Function";
import recordFile from "../../Assets/Images/icon/record.svg";
import emailFile from "../../Assets/Images/icon/mail-01.svg";
import voidFile from "../../Assets/Images/icon/void.svg";
import RecordPayment from "../../Component/RecordPayment/RecordPayment";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";
import Expense from "./Expense";
import { expenseDelete, getExpenseList } from "../../api/expense";

const Billing = () => {
  const navigate = useNavigate();
  const [billingTag, setBillingTag] = useState("invoices");
  const [invoiceTag, setInvoiceTag] = useState("all");
  const [invoiceList, setInvoiceList] = useState<any[]>([]);
  const [expenseList, setExpenseList] = useState<any[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [limitValue, setLimitValue] = useState<number>(0);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [expenseId, setExpenseId] = useState<string>("");

  const [expenseLimit, setExpenseLimit] = useState<number>(10);
  const [expenseTotal, setExpenseTotal] = useState<number>(0);
  const [prevExButton, setPrevExButton] = useState<boolean>(false);
  const [nextExButton, setNextExButton] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);
  const [page, setPage] = useState(1);
  const [expensePage, setExpensePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [expenseTerm, setExpenseTerm] = useState("");
  let initialApiQuery = 'page=1&limit=10&status=all&column=created_at&dir=desc';
  const [apiQuery, setApiQuery] = useState(initialApiQuery);
  const { currencyIcon, fetchCurrency } = useFetchCurrency();
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [expenseStatus, setExpenseStatus] = useState("");

  const handleClose = () => {
    setShow(false);
    setCount(count + 1);
  }
  const [expenseShow, setExpenseShow] = useState(false);
  const handleExpenseClose = () => {
    setExpenseShow(false);
  }

  const [invoiceDetail, setInvoiceDetail] = useState<any>({});

  // call on page change, status change
  const fetchInvoices = (updatedQuery: string) => {
    setPrevButton(false);
    setNextButton(false);
    getInvoicesList(updatedQuery)
      .then((data) => {
        if (data && Array.isArray(data.invoices)) {
          setInvoiceList(data.invoices);
          setTotalValue(data.total);
          setLimitValue(data.limit);
          setPage(data.page);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setPrevButton(true);
        setNextButton(true);
      });
  };

  useEffect(() => {
    fetchInvoices(`?${apiQuery}`);
    fetchCurrency();
  }, [count, show]);

  useEffect(() => {
    setPrevExButton(false);
    setNextExButton(false);
    getExpenseList(10, 1, expenseTerm)
      .then((data) => {
        setExpenseList(data.data);
        setExpenseTotal(data.totalRecords);
        setExpenseLimit(data.limit);
        setExpensePage(data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setPrevExButton(true);
        setNextExButton(true);
      });
  }, [expenseTerm, count, expenseShow]);


  // params = { page: 2, status: 'paid' }
  const updateApiQuery = (params: {}) => {
    const updatedQuery: string = updateQueryString(apiQuery, params);
    if (apiQuery !== updatedQuery) {
      setApiQuery(updatedQuery);
      fetchInvoices(`?${updatedQuery}`);
    }
  };

  const changePage = (newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
      updateApiQuery({ page: newPage });
    }
  };

  const handleSearch = () => {
    if (searchTerm) {
      updateApiQuery({ search: searchTerm })
    }
  };

  const handleExpenseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpenseTerm(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      updateApiQuery({ search: '' })
    };
  };

  const prevPage = () => {
    if (page > 1) {
      updateApiQuery({ page: page - 1 });
    };
  };

  const nextPage = () => {
    if (page < totalValue / limitValue) {
      updateApiQuery({ page: page + 1 });
    }
  };

  const prevExPage = () => {
    if (page > 1) {
      setExpensePage(expensePage - 1)
    };
  };

  const nextExPage = () => {
    if (page < totalValue / limitValue) {
      setExpensePage(expensePage + 1)
    }
  };


  const sortInvoices = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedInvoices = [...invoiceList].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setInvoiceList(sortedInvoices);
    setSortConfig({ key, direction });
  };

  const viewInv = (invId: string) => {
    return navigate(`/invoice-details/${invId}`)
  };

  const paymentView = (invInfo: any) => {
    setShow(true);
    setInvoiceDetail(invInfo);
  };

  const voidUpdate = (invId: string) => {
    const paymentInfo = {
      void: "void",
    };
    if (invId) {
      paymentVoid(invId, paymentInfo)
        .then(() => {
          setCount(count + 1);
          showNotifications(
            TOAST_TYPE.success,
            "Invoice Voided",
            ''
          );
        })
        .catch((err) => {
          // showNotifications(TOAST_TYPE.error, err.message);
          console.log(err);
        });
    }
  };

  const exDelete = (deleteId: number) => {
    expenseDelete(deleteId).then(() => {
      showNotifications(TOAST_TYPE.success, 'File Deleted', '');
      setCount(count + 1);
    }).catch((err) => {
      console.log(err);
    })
  }


  const expenseEdit = (expenseId: string) => {
    setExpenseShow(true);
    setExpenseId(expenseId);
    setExpenseStatus("edit");
  }

  const expenseView = (expenseId: string) => {
    setExpenseShow(true);
    setExpenseId(expenseId);
    setExpenseStatus("view");
  }



  return (
    <div id='billing'>
      <Layout>
        <div className='mainContent'>
          <div className="invoiceHeading">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0 ms-2">
                <li className="breadcrumb-item px-0"><Link to="/billing">Billing</Link></li>
                <li className="breadcrumb-item active" aria-current="page">All Invoices</li>
              </ol>
            </nav>
            <div className="invoiceChoose">
              <button onClick={() => setBillingTag("invoices")}>Invoices</button>
              <button onClick={() => setBillingTag("expenses")}>Expenses</button>
            </div>
          </div>
          {/* billing invoice */}
          {billingTag === 'invoices' ?
            <div className='memberBox'>
              <div className='topLine'>
                <div className='tableHeading'>
                  <h6>All Invoices</h6>
                </div>
                <div className='memberSearch'>
                  <div className='searchInput' style={{ marginRight: '15px' }}>
                    <input
                      type='text'
                      placeholder='Search billing'
                      onChange={handleInputChange}
                      onKeyDown={(e) => e.key === 'Enter' && updateApiQuery({ search: searchTerm })}
                      className='form-control'
                    />

                    <FontAwesomeIcon
                      style={{ color: searchTerm ? '' : '#ddd' }}
                      className={searchTerm && 'cursor-pointer'}
                      onClick={handleSearch} icon={faSearch}
                    />
                  </div>
                  <div className='filterDropdown'>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <button className='filterBtn'>
                          {invoiceTag ? invoiceTag : "Status"}{" "}
                          <img src={arrowDown} alt='filter' />
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                          updateApiQuery({ status: "all" })
                          setInvoiceTag('All');
                        }}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          updateApiQuery({ page: 1, status: "unpaid" });
                          setInvoiceTag('Unpaid');
                        }}
                        >
                          Unpaid
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          updateApiQuery({ page: 1, status: "paid" });
                          setInvoiceTag('Paid');
                        }}
                        >
                          Paid
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          updateApiQuery({ status: 'void', page: 1 });
                          setInvoiceTag('Void');
                        }}
                        >
                          Void
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          updateApiQuery({ status: 'pending', page: 1 });
                          setInvoiceTag('Upcoming');
                        }}
                        >
                          Upcoming
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Link to='/create-invoice'>
                    <FontAwesomeIcon icon={faPlus} /> Create Manual Invoice
                  </Link>
                </div>
              </div>
              <div className='billingList' style={{ height: 'calc(100vh - 282px)' }}>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>
                        <label className='tableCheckBox'>
                          <div className='contactCheck'>
                            <input type='checkbox' name='agreement' />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </th>
                      <th onClick={() => sortInvoices("invoice_id")}>
                        ID{" "}
                        {sortConfig?.key === "invoice_id" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("user_name")}>
                        Member{" "}
                        {sortConfig?.key === "user_name" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("created_at")}>
                        Date Created{" "}
                        {sortConfig?.key === "created_at" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("status")}>
                        Status{" "}
                        {sortConfig?.key === "status" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("total_amount")}>
                        Amount{" "}
                        {sortConfig?.key === "total_amount" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoiceList.map((invoice: any, i: number) => (
                        <tr key={`filtered` + i}>
                          <td>
                            <label className='tableCheckBox'>
                              <div className='contactCheck'>
                                <input type='checkbox' name='agreement' />
                                <span className='checkmark'></span>
                              </div>
                            </label>
                          </td>
                          <td>
                            <Link to={`/invoice-details/${invoice.id}`}>
                              #INV{invoice.invoice_id.toString().padStart(3, "0")}
                            </Link>
                          </td>
                          <td>
                            {invoice.member_image ? (
                              <img
                                src={`${API}/${invoice.member_image}`}
                                width='32px'
                                height='32px'
                                alt='avatar'
                                style={{ borderRadius: "50%" }}
                              />
                            ) : (
                              <img
                                className='default'
                                src={memberAvatar}
                                width='32px'
                                height='32px'
                                alt='avatar'
                                style={{ borderRadius: "50%" }}
                              />
                            )}
                            {invoice.user_name}
                          </td>
                          <td>
                            {moment(invoice.created_at).format("MMMM DD, YYYY")}
                          </td>
                          <td className='status'>
                            <span className={invoice.status === 'paid' ? 'paid' : 'unpaid'}>
                              {capitalizeFirstLetter(invoice.status)}
                            </span>
                          </td>
                          <td>{currencyIcon} {parseFloat(invoice.amount).toFixed(2)}</td>
                          <td className='billingAction'>
                            <Dropdown className='taskIcon download' style={{ borderRadius: '6px' }} onMouseDown={(e) => e.stopPropagation()}>
                              <Dropdown.Toggle id="dropdown-basic" style={{ borderRadius: '6px' }}>
                                <img src={more} alt='download' />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => paymentView(invoice)}>
                                  Record Manual Payment
                                </Dropdown.Item>
                                <Dropdown.Item >
                                  Send Reminder
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => voidUpdate(invoice.id)}>
                                  Void
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => viewInv(invoice.id)}>
                                  View Invoice
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Pagination
                  page={page}
                  pageValue={page}
                  paginationTitle='Invoices'
                  setPage={changePage}
                  limit={limit}
                  setLimit={setLimit}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  totalValue={totalValue}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  allRequestList={invoiceList}
                />
              </div>
            </div> : ""}

          {/* Expenses invoice */}
          {billingTag === 'expenses' ?
            <div className='memberBox'>
              <div className='topLine'>
                <div className='tableHeading'>
                  <h6>All Expenses</h6>
                </div>
                <div className='memberSearch'>
                  <div className='searchInput' style={{ marginRight: '15px' }}>
                    <input
                      type='text'
                      placeholder='Search expense'
                      onChange={handleExpenseChange}
                      className='form-control'
                    />

                    <FontAwesomeIcon
                      style={{ color: searchTerm ? '' : '#ddd' }}
                      className={searchTerm && 'cursor-pointer'}
                      onClick={handleSearch} icon={faSearch}
                    />
                  </div>
                  <button onClick={() => { setExpenseShow(true); setExpenseStatus("edit") }}>
                    <FontAwesomeIcon icon={faPlus} />Submit Expense
                  </button>
                </div>
              </div>
              <div className='billingList' style={{ height: 'calc(100vh - 282px)' }}>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        <label className='tableCheckBox'>
                          <div className='contactCheck'>
                            <input type='checkbox' name='agreement' />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </th>
                      <th onClick={() => sortInvoices("invoice_id")}>
                        ID{" "}
                        {sortConfig?.key === "invoice_id" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("user_name")}>
                        Item{" "}
                        {sortConfig?.key === "user_name" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("created_at")}>
                        Date Submitted{" "}
                        {sortConfig?.key === "created_at" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("status")}>
                        Category{" "}
                        {sortConfig?.key === "status" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th onClick={() => sortInvoices("total_amount")}>
                        Amount{" "}
                        {sortConfig?.key === "total_amount" && (
                          <FontAwesomeIcon
                            icon={
                              sortConfig.direction === "ascending"
                                ? faArrowUp
                                : faArrowDown
                            }
                          />
                        )}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseList &&
                      expenseList.map((expense: any, i: number) => (
                        <tr key={`filtered` + i}>
                          <td>
                            <label className='tableCheckBox'>
                              <div className='contactCheck'>
                                <input type='checkbox' name='agreement' />
                                <span className='checkmark'></span>
                              </div>
                            </label>
                          </td>
                          <td>
                            <Link to="">
                              #EXP{expense.id}
                            </Link>
                          </td>
                          <td>
                            {expense.item}
                          </td>
                          <td>
                            {moment(expense.created_at).format("MMMM DD, YYYY")}
                          </td>
                          <td>{expense.category}</td>
                          <td>{currencyIcon} {expense.amount}</td>
                          <td className='billingAction'>
                            <Dropdown className='taskIcon download' style={{ borderRadius: '6px' }} onMouseDown={(e) => e.stopPropagation()}>
                              <Dropdown.Toggle id="dropdown-basic" style={{ borderRadius: '6px' }}>
                                <img src={more} alt='download' />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => expenseView(expense.id)}>
                                  View Expense
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => expenseEdit(expense.id)}>
                                  Edit Expense
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => exDelete(expense.id)}>
                                  Delete Expense
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Pagination
                  page={expensePage}
                  pageValue={expensePage}
                  paginationTitle='Expense'
                  setPage={changePage}
                  limit={expenseLimit}
                  setLimit={setExpenseLimit}
                  prevButton={prevExButton}
                  nextButton={nextExButton}
                  totalValue={expenseTotal}
                  prevPage={prevExPage}
                  nextPage={nextExPage}
                  allRequestList={expenseList}
                />
              </div>
            </div> : ""}

        </div>

        <RecordPayment
          invoiceId={invoiceDetail.id}
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          invoiceDetail={invoiceDetail}
        />

        <Expense expenseStatus={expenseStatus} handleExpenseClose={handleExpenseClose} expenseShow={expenseShow} setExpenseShow={setExpenseShow} expenseId={expenseId} />

      </Layout>
    </div>
  );
};

export default Billing;
