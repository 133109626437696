import { del, get, post, put } from "./base-api";

// expense Create
export const expenseCreate = (body = {}) => {
    return post('expenseCreate', body, 'multipart/form-data');
};
// expense list
export const getExpenseList = (limit: number, page: number, search: string) => {
    return get(`/expenseList?limit=${limit}&page=${page}&search=${search}`);
};
// expense delete
export const expenseDelete = (id: number) => {
    return del(`/expenseDelete/${id}`);
};
// expense view
export const expenseView = (id: string | undefined) => {
    return get(`/expenseView/${id}`);
};
// expense update
export const expenseUpdate = (expenseId: string | undefined, body = {}) => {
    return put(`expenseUpdate/${expenseId}`, body, 'multipart/form-data');
};