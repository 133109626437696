import { faXmark, faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { convertBytesToSize, useFetchCurrency } from '../../CommonFunction/Function';
import { Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import expenseLogo from "../../Assets/Images/icon/expenseLogo.svg";
import uploadFile from "../../Assets/Images/icon/uploadIcon.svg";
import fileFormat from "../../Assets/Images/icon/file-05.svg";
import trash from "../../Assets/Images/icon/trash-02.svg";
import DatePicker from 'react-datepicker';
import { expenseCreate, expenseUpdate, expenseView } from '../../api/expense';
import { DESKIE_API as API } from "../../config";

interface AddExpenseProps {
    handleExpenseClose: () => void;
    expenseShow: boolean;
    setExpenseShow: (type: boolean) => void;
    expenseId?: string;
    expenseStatus: string;
}

const Expense = ({ handleExpenseClose, expenseShow, setExpenseShow, expenseId, expenseStatus }: AddExpenseProps) => {

    const [file, setFile] = useState("");
    const [expenseFiles, setExpenseFiles] = useState("");
    const { currencyIcon, fetchCurrency } = useFetchCurrency();
    const [selectedTag, setSelectedTag] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [nickName, setNickName] = useState("");
    const [dueDate, setDueDate] = useState<any>(new Date());
    const [itemName, setItemName] = useState("");
    const [amount, setAmount] = useState("");
    const [notes, setNotes] = useState("");


    const wrapperRef = useRef<HTMLInputElement>(null);
    const onFileDrop = (event: any) => {
        const imageFile = event.target.files && event.target.files[0];
        const fileName = imageFile.name.split('.').slice(0, -1).join('.');
        setNickName(fileName);
        if (imageFile && imageFile.size <= 26 * 1024 * 1024) { // Convert MB to bytes
            setFile(imageFile);
            setExpenseFiles("");
            if (imageFile && uploadedFiles.length === 0) {
                setUploadedFiles([imageFile]);
            }
        } else {
            showNotifications(TOAST_TYPE.error, 'Maximum upload size 26 MB');
        }
    }

    // remove file
    const removeFile = () => {
        setUploadedFiles([]);
    }
    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            setSelectedTag(eventKey);
        }
    };
    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        setDueDate(selectedDate)
    }
    const changeDateStyle = (value: string) => {
        const [month, day, year] = value.split("/").map(Number);
        const date = new Date(year, month - 1, day);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        return formattedDate;
    }
    const CustomDatePickerInput: React.FC<any> = forwardRef(({ value, onClick }: any, ref: any) => (
        <button className="taskDate" onClick={onClick}>
            {changeDateStyle(value)}
            <FontAwesomeIcon icon={faChevronDown} />
        </button>
    ));
    const handleTodayClick = () => {
        setDueDate(new Date());
    };

    const handleYesterdayClick = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setDueDate(yesterday);
    };
    const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }: any) => (
        <div>
            <div className='calenderHeading'>
                <button className='arrowLeft' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}><FontAwesomeIcon icon={faChevronLeft} /></button>
                <span className='calenderDate'>{date.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
                <button className='arrowRight' onClick={increaseMonth} disabled={nextMonthButtonDisabled}><FontAwesomeIcon icon={faChevronRight} /></button>
            </div>
            <div className='calenderBtn'>
                <button onClick={handleYesterdayClick}>Yesterday</button>
                <button onClick={handleTodayClick}>Today</button>
            </div>
        </div>
    );
    useEffect(() => {
        fetchCurrency();
    }, []);

    const expenseSubmit = () => {
        let expenseInfo = {
            "item": itemName,
            "amount": amount,
            "notes": notes,
            "category": selectedTag,
            "expense_date": dueDate,
            "receipt": file
        }
        expenseCreate(expenseInfo).then(() => {
            showNotifications(TOAST_TYPE.success, 'Expense Uploaded', ``);
        }).catch(() => {
            showNotifications(TOAST_TYPE.error, 'Something wrong');
        }).finally(() => {
            setExpenseShow(false);
        })
    }

    useEffect(() => {
        if (expenseId) {
            expenseView(expenseId).then((data) => {
                setItemName(data.item);
                setAmount(data.amount);
                setNotes(data.notes);
                setSelectedTag(data.category);
                setDueDate(data.expense_date);
                setExpenseFiles(data.receipt);
            }).catch(() => {
                showNotifications(TOAST_TYPE.error, 'Something wrong');
            })
        }
    }, [expenseId]);

    const getImageName = (input: string): string => {
        const parts = input.split("-");
        return parts.length > 1 ? parts.pop() || "" : input;
    };
    const removeExpenseFile = () => {
        setExpenseFiles("");
    }

    const expenseEdit = () => {
        let expenseInfo = {
            "item": itemName,
            "amount": amount,
            "notes": notes,
            "category": selectedTag,
            "expense_date": dueDate,
            "receipt": file
        }

        expenseUpdate(expenseId, expenseInfo).then((data) => {
            setExpenseShow(false);
        }).catch(() => {
            showNotifications(TOAST_TYPE.error, 'Something wrong');
        })
    }

    return (
        <Modal show={expenseShow} onHide={handleExpenseClose} centered size="lg">
            <div className="addMemberForm">
                <button className='closeModal' onClick={handleExpenseClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                {expenseStatus && expenseStatus === "edit" ? <Container className='px-0'>
                    <Row>
                        <Col md={12}>
                            <div className='addMemberHeading'>
                                <img src={expenseLogo} alt="expense" />
                                <p>{expenseId ? 'Update' : "Submit"} Expense</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <div className="memberInput">
                                        <label>Item</label>
                                        <input type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} placeholder='Item' className='form-control' />
                                    </div>
                                    <div className="memberInput">
                                        <label>Category</label>
                                        <Dropdown onSelect={handleSelect}>
                                            <Dropdown.Toggle variant="" className="custom-toggle">
                                                {selectedTag === "property"
                                                    ? "Property"
                                                    : selectedTag === "utilities"
                                                        ? "Utilities"
                                                        : selectedTag === "payroll"
                                                            ? "Payroll"
                                                            : selectedTag === "maintenance"
                                                                ? "Maintenance"
                                                                : selectedTag === "supplies"
                                                                    ? "Supplies"
                                                                    : selectedTag === "hospitality"
                                                                        ? "Hospitality"
                                                                        : selectedTag === "miscellaneous"
                                                                            ? "Miscellaneous"
                                                                            : "Choose a Category"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="property">Property</Dropdown.Item>
                                                <Dropdown.Item eventKey="utilities">Utilities</Dropdown.Item>
                                                <Dropdown.Item eventKey="payroll">Payroll</Dropdown.Item>
                                                <Dropdown.Item eventKey="maintenance">Maintenance</Dropdown.Item>
                                                <Dropdown.Item eventKey="supplies">Supplies</Dropdown.Item>
                                                <Dropdown.Item eventKey="hospitality">Hospitality</Dropdown.Item>
                                                <Dropdown.Item eventKey="miscellaneous">Miscellaneous</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <button>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="memberInput rate">
                                        <span>{currencyIcon}</span>
                                        <label>Amount</label>
                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Rate' className='form-control' />
                                        <button>USD</button>
                                    </div>
                                    <div>
                                        <div className="memberInput">
                                            <label>Date</label>
                                        </div>

                                        <div className="dateShow">
                                            <DatePicker selected={dueDate} onChange={dueDateChange} placeholderText="Select a date" dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} renderCustomHeader={CustomHeader} />
                                        </div>
                                    </div>


                                </Col>
                                <Col md={12}>
                                    <div className="memberInput">
                                        <label>Notes</label>
                                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} placeholder='Additional Notes' className='form-control' />
                                    </div>
                                    <div className="memberInput">
                                        <label className='mt-5'>Receipt</label>
                                    </div>

                                    <div ref={wrapperRef} className="drop-file-input">
                                        <div className="drop-file-input__label">
                                            <img src={uploadFile} alt="" />
                                            <p><span>Click to upload</span> or drag and drop</p>
                                            <h6>Maximum upload size <b>26 MB</b></h6>
                                        </div>
                                        <input type="file" value="" onChange={onFileDrop} />
                                    </div>
                                    {uploadedFiles && uploadedFiles.map((file, index) =>
                                        <div className="uploadFileShow">
                                            <div className="fileFormat">
                                                <img src={fileFormat} alt="file" />
                                            </div>
                                            <div className="fileName">
                                                <p>{file.name}</p>
                                                <span>{convertBytesToSize(file.size)} – 100% uploaded</span>
                                            </div>
                                            <div className="fileDelete" onClick={removeFile}>
                                                <img src={trash} alt="trash" />
                                            </div>
                                        </div>
                                    )}
                                    {expenseFiles && expenseFiles ? <div className="uploadFileShow">
                                        <div className="fileFormat">
                                            <img src={fileFormat} alt="file" />
                                        </div>
                                        <div className="fileName">
                                            <p>{getImageName(expenseFiles)}</p>
                                            <span>100% uploaded</span>
                                        </div>
                                        <div className="fileDelete" onClick={removeExpenseFile}>
                                            <img src={trash} alt="trash" />
                                        </div>
                                    </div> : ""}
                                </Col>
                            </Row>
                        </Col>

                        <div className="memberAddBtn">
                            {expenseId ? <button type='submit' onClick={expenseEdit} className='save'>Update</button> : <button type='submit' onClick={expenseSubmit} className='save'>Save</button>}

                        </div>
                    </Row>
                </Container> : <Container className='px-0'>
                    <Row>
                        <Col md={12}>
                            <div className='addMemberHeading'>
                                <img src={expenseLogo} alt="expense" />
                                <p>View Expense</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <div className="memberInput">
                                        <label>Item</label>
                                        <input type="text" value={itemName} placeholder='Item' className='form-control' readOnly />
                                    </div>
                                    <div className="memberInput">
                                        <label>Category</label>
                                        <input type="text" value={selectedTag} placeholder='Item' className='form-control' readOnly />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="memberInput rate">
                                        <span>{currencyIcon}</span>
                                        <label>Amount</label>
                                        <input type="number" value={amount} placeholder='Rate' className='form-control' readOnly />
                                        <button>USD</button>
                                    </div>
                                    <div>
                                        <div className="memberInput">
                                            <label>Date</label>
                                        </div>

                                        <div className="dateShow">
                                            <DatePicker selected={dueDate} onChange={dueDateChange} placeholderText="Select a date" dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} renderCustomHeader={CustomHeader} disabled />
                                        </div>
                                    </div>


                                </Col>
                                <Col md={12}>
                                    <div className="memberInput">
                                        <label>Notes</label>
                                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} readOnly placeholder='Additional Notes' className='form-control' />
                                    </div>
                                    <div className="memberInput">
                                        <label className='mt-5'>Receipt</label>
                                    </div>


                                    {expenseFiles && expenseFiles ? <div className="uploadFileShow">
                                        <img src={`${API}/${expenseFiles}`} alt="file" width="100%" />
                                    </div> : ""}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>}


            </div>
        </Modal>
    )
}

export default Expense