import http from "../utils/http";
import { del, get, post, put } from "./base-api";

// task add
export const resourceAdd = (body = {}) => {
  return post(`/resourceCreate`, body, "multipart/form-data");
};

// resource list
export const resourceList = (limit: number, page: number, sort: string) => {
  return get(`/resourceList?limit=${limit}&page=${page}&type=${sort}`);
};

export const publicResourceList = (
  limit: number,
  page: number,
  sort: string,
  role: string
) => {
  return get(`/public/resource?limit=${limit}&page=${page}&type=${sort}&role=${role}`);
};

// single resource
export const singleResource = (id: string) => {
  return get(`/singleResource/${id}`);
};
// resourceBooking
export const resourceBooking = (body = {}) => {
  return post(`/resourceBooking`, body);
};

export const publicResourceBooking = (body = {}) => {
  return post(`/public/resource-book`, body);
};

// admin resource list
export const adminResourceList = () => {
  return get(`/adminResourceList`);
};
// single resource
export const resourceBook = (id: string) => {
  return get(`/resourceBook/${id}`);
};
// single public resource
export const publicResourceBook = (id: string) => {
  return get(`/publicResourceBook/${id}`);
};

// resource update
export const resourceUpdate = (id: string, body = {}) => {
  return put(`/resourceUpdate/${id}`, body, "multipart/form-data");
};
// resource active
export const resourceActive = (id: string) => {
  return put(`/resourceActive/${id}`);
};


// resourceBooking
export const resourceInvoice = (body = {}) => {
  return post(`/resourceInvoice`, body);
};
// resource Invoice List
export const resourceInvoiceList = () => {
  return get(`/resourceInvoiceList`);
};

// Resource booked time slots
export const resourceBookTime = (id: string) => {
  return get(`/resourceBookTime/${id}`);
};

export const publicResourceBookedTimeSlots = (id: string) => {
  return get(`/public/resource-booked-time-slots/${id}`);
};

// book List
export const bookList = () => {
  return get(`/bookList`);
};

// delete Resource Booking
export const deleteResourceBooking = (resourceBookingID: string) => {
  return del(`/resource-booking/${resourceBookingID}`);
};

// delete Resource Booking
export const fetchResourceCalendarFeed = () => {
  return http("application/json", "text/calendar").get("/calendar-feed.ics");
};
