import React, { useState, useEffect } from 'react'
import { Dropdown, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../Component/Layout/Layout';
import filter from '../../Assets/Images/icon/filter-lines.svg';
import "./Ticket.css";
import { ticketList, ticketUpdate } from '../../api/tickets';
import moment from 'moment';
import Pagination from '../../Component/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { ToastContainer } from 'react-toastify';
import ReportIssue from '../ReportIssue/ReportIssue';

const Ticket = () => {

    const [tickets, setTickets] = useState([]);
    const [ticketBox, setTicketBox] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [limitValue, setLimitValue] = useState<number>(0);
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [ticketTag, setTicketTag] = useState("");
    const [memberName, setMemberName] = useState("");
    const [issueType, setIssueType] = useState(0);

    useEffect(() => {
        ticketList(limit, page, ticketTag, memberName).then((data) => {
            if (data.statusCode === 200) {
                setTickets(data.data);
                setTotalValue(data.total)
                setLimitValue(data.limit)
                setPageValue(data.page)
            }
        }).catch((err) => { console.log(err) });
    }, [limit, page, ticketTag, memberName, issueType, ticketBox]);

    useEffect(() => {
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [pageCount, page])


    const nextPage = () => {
        setPage(page + 1)
        setNextButton(true)
    }

    const prevPage = () => {
        setPage(page - 1)
    }

    const ticketStatus = (id: string) => {
        if (id) {
            ticketUpdate(id).then((data) => {
                setIssueType(issueType + 1)
                showNotifications(TOAST_TYPE.success, "Status Change Successfully");
            }).catch((err) => { console.log(err) });
        }
    }

    const addTicket = () => {
        setTicketBox(true)
    }

    return (
        <>
            {ticketBox ? <ReportIssue setTicketBox={setTicketBox} /> :
                <Layout>
                    <div className='mainContent'>
                        <ToastContainer />
                        <div className="memberBox">
                            <div className="topLine">
                                <div className='tableHeading'>
                                    <h6>All Tickets</h6>
                                </div>
                                <div className='memberSearch'>
                                    <div className='searchInput' style={{ marginRight: '15px' }}>
                                        <input type="text" value={memberName} onChange={(e) => setMemberName(e.target.value)} placeholder='Search ticket' className='form-control' />
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                    <div className='filterDropdown'>
                                        <Dropdown>
                                            <Dropdown.Toggle>
                                                <button className='filterBtn'>{ticketTag ? ticketTag : 'Status'} &nbsp; <img src={filter} alt='filter' /></button>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setTicketTag("all")}>All</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setTicketTag("closed")}>Closed</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setTicketTag("open")}>Open</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <button onClick={addTicket}><FontAwesomeIcon icon={faPlus} /> Add Ticket</button>
                                </div>
                            </div>

                            <div className="ticketList">
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>
                                                <label className="tableCheckBox">
                                                    <input type="checkbox" name="agreement" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </th>
                                            <th>Ticket ID</th>
                                            <th>Name</th>
                                            <th>Subject</th>
                                            <th>Updated</th>
                                            <th>Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets && tickets.map((ticket: any, i: number) => <tr>
                                            <td>
                                                <label className="tableCheckBox">
                                                    <input type="checkbox" name="agreement" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td className='ticketsLink'><Link to={`/ticket/${ticket.id}`}>#{i + 1}</Link></td>
                                            <td>{ticket.member_name}</td>
                                            <td>{ticket.report_type}</td>
                                            <td>{moment(ticket.updated_at).format("MMMM DD, YYYY")}</td>
                                            <td className='status'>
                                                <span onClick={() => ticketStatus(ticket.id)} style={{ cursor: 'pointer' }} className={ticket.status === "open" ? 'closed' : 'open'}>{ticket.status === "open" ? "Open" : "Closed"}</span>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                                <Pagination page={page} paginationTitle="items" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={tickets} />
                            </div>
                        </div>
                    </div>
                </Layout>}
        </>
    )
}

export default Ticket